import React from 'react';
import { Box, Heading, Grid, GridItem, AspectRatio } from '@chakra-ui/react';
import Share from '@components/molecules/Share';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import LazyLoad from '../atoms/LazyLoad';

const ArticleHero = ({
  data: { publishDate, title, image, seo, location = [] }
}) => {
  return (
    <Box
      as="section"
      position="relative"
      pt={headerHeight}
      mb={{ base: 4, md: 16 }}>
      <LazyLoad>
        <Wrapper>
          <Grid templateColumns={{ md: `repeat(12, 1fr)` }} columnGap={5}>
            {image && (
              <GridItem
                colStart={{ md: 2 }}
                colSpan={{ md: 10 }}
                mb={{ base: 10, md: 12 }}>
                <AspectRatio ratio={2 / 1} overflow="hidden" display="block">
                  <Image image={image} loading="eager" />
                </AspectRatio>
              </GridItem>
            )}
            <GridItem
              colStart={{ md: 3 }}
              colSpan={{ md: 8 }}
              textAlign="center">
              {publishDate && (
                <Heading
                  as="p"
                  size="sm"
                  color="colours.white"
                  textTransform="uppercase"
                  letterSpacing="0.11rem"
                  opacity="1"
                  mb="9">
                  {publishDate}
                </Heading>
              )}
              {title && (
                <Heading
                  as="h1"
                  fontSize={{
                    base: `4xl`,
                    lg: `5xl`,
                    xl: `6xl`
                  }}
                  position="relative"
                  mb={10}
                  textTransform="uppercase"
                  textAlign="center"
                  zIndex="1">
                  {title}
                </Heading>
              )}
              <Share
                position="relative"
                zIndex="1"
                data={{
                  metaTitle: seo?.metaTitle,
                  metaDescription: seo?.metaDescription,
                  link: location.href
                }}
              />
            </GridItem>
          </Grid>
        </Wrapper>
      </LazyLoad>
    </Box>
  );
};

export default ArticleHero;
