import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  AspectRatio,
  useDisclosure
} from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import { CloseIcon } from '@components/atoms/icons';

const ModalVideo = ({ videoID, videoTitle, renderControl }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {typeof renderControl === `function` && renderControl(onOpen)}
      <Modal
        size="4xl"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        returnFocusOnClose={false}>
        <ModalOverlay bg="rgb(0, 28, 73, .7)" />
        <ModalContent
          position="relative"
          mx={{ base: 5, md: 14 }}
          borderRadius="none"
          boxShadow="none"
          bg="black">
          {videoID && (
            <AspectRatio ratio={16 / 9}>
              <iframe
                title={videoTitle}
                src={`https://player.vimeo.com/video/${videoID}?autoplay=true`}
                allow="autoplay; fullscreen; accelerometer; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
          )}
        </ModalContent>
        <Button
          color="primary.dark-blue"
          size="sm"
          icon={<CloseIcon color="white" boxSize="6" />}
          px="3"
          position="fixed"
          top="1"
          right="1"
          m="4"
          aria-label="Close"
          onClick={onClose}
          zIndex="modal"
        />
      </Modal>
    </>
  );
};

export default ModalVideo;
