import React from 'react';
import { Button as ChakraButton, Center, Box } from '@chakra-ui/react';
import withLink from '@components/atoms/withLink';
import { ArrowRightIcon } from '@components/atoms/icons';

const ButtonIconCircle = () => (
  <Center
    as="span"
    width="9"
    height="9"
    border="1px solid"
    borderRadius="100%"
    ml=".625rem"
    position="relative"
    _before={{
      content: `""`,
      borderRadius: `100%`,
      bg: `colours.goldGradient`,
      position: `absolute`,
      opacity: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: `.4s`,
      '.chakra-button:hover &, .button-hover:hover &, .chakra-menu__menu-button:hover &':
        {
          opacity: 1
        }
    }}
    sx={{
      '.chakra-button:hover &, .button-hover:hover &, .chakra-menu__menu-button:hover &':
        {
          borderColor: `colours.gold`
        },
      '.chakra-button:hover & > svg, .button-hover:hover & > svg, .chakra-menu__menu-button:hover &  > svg':
        {
          color: `colours.darkPurple`
        }
    }}>
    <ArrowRightIcon color="colours.white" w="4" h="4" zIndex="2" />
  </Center>
);

const Button = ({
  children = null,
  variant = `primary`,
  size = { base: `sm`, md: `md` },
  icon,
  iconSpacing = `0`,
  ...props
}) => {
  return (
    <ChakraButton variant={variant} size={size} {...props}>
      {children && (
        <Box
          position="relative"
          as="span"
          // Vertically center text with padding to accomodate for odd line height.
          pt=".125em"
          _after={{
            content: `""`,
            position: `absolute`,
            left: `0`,
            bottom: `0`,
            width: `100%`,
            height: `.0625rem`,
            backgroundColor: `white`,
            opacity: `0`,
            transition: `opacity .3s ease-in-out`
          }}
          sx={{
            '.button-hover:hover &': {
              '&::after': {
                opacity: 1
              }
            }
          }}>
          {children}
        </Box>
      )}
      {icon && (
        <Box ml={iconSpacing}>
          <ButtonIconCircle />
        </Box>
      )}
    </ChakraButton>
  );
};

export default Button;

export const ButtonLink = withLink(Button);
