import React, { useRef, useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Box, AspectRatio, Heading } from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import buttonBgGradient from '@assets/images/button-bg-gradient.svg';

const ProductCard = ({ basePath, product, yMargin = true }) => {
  const headingRef = useRef(null);
  const [headingHeight, setHeadingHeight] = useState(0);

  useEffect(() => {
    setHeadingHeight(headingRef.current?.offsetHeight);
  }, []);

  return (
    <Box
      as={GatsbyLink}
      to={`${basePath}/${product.slug}`}
      display="block"
      className="product-hover"
      position="relative">
      <AspectRatio
        ratio={1}
        display="block"
        transition="transform .5s ease-out"
        sx={{
          '.product-hover:hover &': {
            transform: { md: `auto` },
            scale: { md: `1.1` }
          }
        }}
        w="80%"
        ml="10%"
        my="10%"
        mt={yMargin ? null : 0}>
        <Image image={product.thumbnail} objectFit="contain" />
      </AspectRatio>
      <Box
        backgroundClip="text"
        backgroundImage={`/**/url("${buttonBgGradient}"), linear-gradient(90deg, white 0%, white 100%)`}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition={`0 ${headingHeight}px, 0 0`}
        position="relative"
        w="fit-content"
        h="fit-content"
        mx="auto"
        sx={{
          '.product-hover:hover &': {
            backgroundPosition: `0 -4rem, 0 0`,
            transition: `background-position .7s ease-in-out`
          }
        }}
        transition="background-position 0.5s ease-out">
        <Heading
          ref={headingRef}
          as="h2"
          size="2xl"
          letterSpacing="0.2475rem"
          color="transparent"
          textAlign="center"
          textTransform="uppercase"
          backgroundImage="linear-gradient(90deg, #cea558 0%, #fbe09b 40%, #cea558 100%)"
          backgroundPosition={`0 ${headingHeight}px`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          transition="background-position .5s 0s ease-in-out"
          mb={yMargin ? null : `0`}
          sx={{
            '.product-hover:hover &': {
              backgroundPosition: `0 0`,
              transition: `background-position .7s .1s ease-out`
            }
          }}>
          {product.title}
        </Heading>
      </Box>
    </Box>
  );
};

export default ProductCard;
