exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dev-components-js": () => import("./../../../src/pages/_dev/components.js" /* webpackChunkName: "component---src-pages-dev-components-js" */),
  "component---src-pages-dev-foundations-js": () => import("./../../../src/pages/_dev/foundations.js" /* webpackChunkName: "component---src-pages-dev-foundations-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-listing-js": () => import("./../../../src/templates/article-listing.js" /* webpackChunkName: "component---src-templates-article-listing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-listing-js": () => import("./../../../src/templates/product-listing.js" /* webpackChunkName: "component---src-templates-product-listing-js" */)
}

