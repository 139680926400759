import React from 'react';
import {
  AspectRatio,
  Box,
  Flex,
  Grid,
  GridItem,
  Wrap,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import LexicalRichText from '@components/atoms/LexicalRichText';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import Image from '@components/atoms/Image';
import LazyLoad from '@components/atoms/LazyLoad';

const ProductDetail = ({
  allergensTitle,
  ingredientsTitle,
  ingredientsText,
  allergens,
  nutritionalTitle,
  nutritionalCaveatText,
  nutritionalTable,
  image
}) => {
  const { siteSettings } = useStaticQuery(graphql`
    {
      siteSettings: strapiSiteSetting {
        nutritionalColumnHeaders {
          gramColumnHeader
          percentColumnHeader
          portionColumnHeader
        }
        nutritionalRowHeaders {
          carbohydrateRowHeader
          energKcalRowHeader
          energyRowHeader
          fatRowHeader
          fibreRowHeader
          proteinRowHeader
          saltRowHeader
          saturatesRowHeader
          sugarRowHeader
        }
      }
    }
  `);

  const thStyles = {
    fontFamily: `body`,
    fontWeight: `700`,
    color: `colours.gold`,
    textTransform: `none`,
    fontSize: `md`,
    px: { base: 2, md: 6 },
    py: { base: 4 }
  };
  const trStyles = {
    borderBottom: `1px solid`,
    borderColor: `colours.gold`
  };
  const tdStyles = {
    padding: { base: `1rem 0.75rem`, md: `1rem 1.5rem` },
    pr: { base: `0.25rem`, md: null }
  };
  return (
    <Section>
      <Wrapper>
        <Flex
          gap={12}
          columnGap={{ xl: `132px` }}
          flexDir={{ base: `column`, md: `row` }}>
          <Box w="100%" maxW="650px">
            <LazyLoad>
              <AspectRatio ratio={1}>
                <Image image={image} />
              </AspectRatio>
            </LazyLoad>
          </Box>
          <Flex gap={12} flexDir="column">
            <LazyLoad>
              <Box>
                {allergensTitle && (
                  <Heading
                    as="h2"
                    fontSize="3xl"
                    textTransform="uppercase"
                    color="colours.white">
                    {allergensTitle}
                  </Heading>
                )}
                <Wrap
                  spacingX={{ base: 6, md: 12 }}
                  spacingY={{ base: 3, md: 6 }}
                  w="max-content"
                  flexWrap="wrap">
                  {typeof allergens !== `undefined` &&
                    allergens.map((allergen) => (
                      <Box key={allergen.title}>
                        <Box w="14" mb="3">
                          <Image image={allergen.image} mx="auto" />
                        </Box>
                        <Text
                          fontFamily="heading"
                          fontSize="md"
                          lineHeight="1.2"
                          textAlign="center"
                          textTransform="uppercase"
                          mb="0">
                          {allergen.title}
                        </Text>
                      </Box>
                    ))}
                </Wrap>
              </Box>
            </LazyLoad>
            <LazyLoad>
              <Box w="100%" maxW="26.625rem">
                {ingredientsTitle && (
                  <Heading
                    as="h2"
                    textTransform="uppercase"
                    fontSize="3xl"
                    color="colours.white">
                    {ingredientsTitle}
                  </Heading>
                )}
                {ingredientsText && (
                  <Box fontSize="14px">
                    <LexicalRichText
                      data={{ richText: { ...ingredientsText.richText } }}
                      paddingSides="0"
                      maxWidth="none"
                    />
                  </Box>
                )}
              </Box>
            </LazyLoad>
          </Flex>
        </Flex>
      </Wrapper>
      {typeof nutritionalTable !== `undefined` && (
        <Wrapper px={{ base: 0, md: 14 }}>
          <Grid
            templateColumns={{ md: `repeat(12, 1fr)` }}
            columnGap={5}
            mt={{ base: 12, md: 28 }}>
            <GridItem colSpan={{ md: 12, xl: 10 }} colStart={{ xl: 2 }}>
              <LazyLoad>
                {nutritionalTitle && (
                  <Heading
                    as="h2"
                    textTransform="uppercase"
                    fontSize="3xl"
                    color="colours.white"
                    px={{ base: 6, md: 0 }}>
                    {nutritionalTitle}
                  </Heading>
                )}
                <TableContainer>
                  <Table
                    variant="unstyled"
                    sx={{
                      tableLayout: { md: `fixed` }
                    }}
                    whiteSpace="normal">
                    {typeof nutritionalTable !== `undefined` && (
                      <Thead bgColor="colours.darkPurple">
                        <Tr sx={trStyles}>
                          <Th />
                          <Th sx={thStyles}>
                            {
                              siteSettings.nutritionalColumnHeaders
                                .gramColumnHeader
                            }
                          </Th>
                          <Th sx={thStyles}>
                            {
                              siteSettings.nutritionalColumnHeaders
                                .portionColumnHeader
                            }
                          </Th>
                          <Th sx={thStyles}>
                            {
                              siteSettings.nutritionalColumnHeaders
                                .percentColumnHeader
                            }
                          </Th>
                        </Tr>
                      </Thead>
                    )}
                    {typeof nutritionalTable !== `undefined` && (
                      <Tbody>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {siteSettings.nutritionalRowHeaders.energyRowHeader}
                          </Td>
                          <Td sx={tdStyles}>{nutritionalTable.energyGram}</Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.energyPortion}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.energyPercent}
                          </Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {
                              siteSettings.nutritionalRowHeaders
                                .energKcalRowHeader
                            }
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.energyKcalGram}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.energyKcalPortion}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.energyKcalPercent}
                          </Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {siteSettings.nutritionalRowHeaders.fatRowHeader}
                          </Td>
                          <Td sx={tdStyles}>{nutritionalTable.fatGram}</Td>
                          <Td sx={tdStyles}>{nutritionalTable.fatPortion}</Td>
                          <Td sx={tdStyles}>{nutritionalTable.fatPercent}</Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {
                              siteSettings.nutritionalRowHeaders
                                .saturatesRowHeader
                            }
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.saturatesGram}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.saturatesPortion}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.saturatesPercent}
                          </Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {
                              siteSettings.nutritionalRowHeaders
                                .carbohydrateRowHeader
                            }
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.carbohydrateGram}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.carbohydratePortion}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.carbohydratePercent}
                          </Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {siteSettings.nutritionalRowHeaders.sugarRowHeader}
                          </Td>
                          <Td sx={tdStyles}>{nutritionalTable.sugarsGram}</Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.sugarsPortion}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.sugarsPercent}
                          </Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {siteSettings.nutritionalRowHeaders.fibreRowHeader}
                          </Td>
                          <Td sx={tdStyles}>{nutritionalTable.fibreGram}</Td>
                          <Td sx={tdStyles}>{nutritionalTable.fibrePortion}</Td>
                          <Td sx={tdStyles}>{nutritionalTable.fibrePercent}</Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {
                              siteSettings.nutritionalRowHeaders
                                .proteinRowHeader
                            }
                          </Td>
                          <Td sx={tdStyles}>{nutritionalTable.proteinGram}</Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.proteinPortion}
                          </Td>
                          <Td sx={tdStyles}>
                            {nutritionalTable.proteinPercent}
                          </Td>
                        </Tr>
                        <Tr sx={trStyles}>
                          <Td sx={tdStyles}>
                            {siteSettings.nutritionalRowHeaders.saltRowHeader}
                          </Td>
                          <Td sx={tdStyles}>{nutritionalTable.saltGram}</Td>
                          <Td sx={tdStyles}>{nutritionalTable.saltPortion}</Td>
                          <Td sx={tdStyles}>{nutritionalTable.saltPercent}</Td>
                        </Tr>
                      </Tbody>
                    )}
                  </Table>
                </TableContainer>
                <Text fontSize="sm" mt="6" mb="0" px={{ base: 6, md: 0 }}>
                  {nutritionalCaveatText}
                </Text>
              </LazyLoad>
            </GridItem>
          </Grid>
        </Wrapper>
      )}
    </Section>
  );
};

export default ProductDetail;
