import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Fonts } from '@components';
import { theme } from '@styles/theme';

export const wrapPageElement = ({ element, props }) => {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      {element}
    </ChakraProvider>
  );
};
