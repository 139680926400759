import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import ProductCard from '@components/molecules/ProductCard';
import LazyLoad from '@components/atoms/LazyLoad';

const ProductListing = ({ products, parentSlug }) => {
  return (
    <Section>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        spacing="4rem 2rem"
        mx="6">
        {products.map((product) => (
          <LazyLoad key={product.slug}>
            <Box>
              <ProductCard
                basePath={parentSlug}
                product={product}
                yMargin={false}
              />
            </Box>
          </LazyLoad>
        ))}
      </SimpleGrid>
    </Section>
  );
};

export default ProductListing;
