import React, { useEffect, useRef, useState } from 'react';
import { Box, Heading, Flex, VStack, Text } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import ButtonGroup from '@components/molecules/ButtonGroup';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const CenteredTextBlock = ({
  data: {
    titleLineOne,
    titleLineTwo,
    text,
    ctas,
    backgroundImage,
    removeBottomSpacing
  },
  headingType
}) => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const titleOneRef = useRef(null);
  const titleTwoRef = useRef(null);
  const textRef = useRef(null);
  const btnGroupRef = useRef(null);
  const bgImageRef = useRef(null);
  const [totalTitleHeight, setTotalTitleHeight] = useState(0);

  useEffect(() => {
    const titleOneHeight = titleOneRef.current?.clientHeight || 0;
    const titleTwoHeight = titleTwoRef.current?.clientHeight || 0;
    const totalHeight = titleOneHeight + titleTwoHeight;
    setTotalTitleHeight(totalHeight);
  }, [titleOneRef, titleTwoRef]);

  useEffect(() => {
    const titleTl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top 70%`
      }
    });
    const textTl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top 70%`
      }
    });
    const btnTl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top 70%`
      }
    });
    const bgImgTl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top 70%`
      }
    });

    if (titleOneRef.current && titleTwoRef.current && headerRef.current) {
      titleTl.to([headerRef.current], {
        y: 0,
        opacity: 1,
        duration: 1,
        immediateRender: false
      });
    }
    if (textRef.current) {
      textTl.to(
        [textRef.current],
        { opacity: 1, duration: 0.8, immediateRender: false },
        0.5
      );
    }
    if (btnGroupRef.current) {
      btnTl.to(
        [btnGroupRef.current],
        { opacity: 1, duration: 0.8, immediateRender: false },
        1
      );
    }
    if (bgImageRef.current) {
      bgImgTl.to(
        [bgImageRef.current],
        { opacity: 1, duration: 2, immediateRender: false },
        0.5
      );
    }
  });

  return (
    <Flex
      as="section"
      position="relative"
      bg="colours.purple"
      pt={{ base: 12, md: 36 }}
      pb={removeBottomSpacing ? 0 : { base: 12, md: 36 }}>
      {backgroundImage && (
        <Box
          ref={bgImageRef}
          position="absolute"
          w="100%"
          h="100%"
          left="0"
          right="0"
          top="0"
          bottom="0"
          zIndex="0"
          pointerEvents="none"
          opacity={0}
          willChange="opacity">
          <Image image={backgroundImage} />
        </Box>
      )}
      <Wrapper zIndex="1">
        <VStack
          alignItems="normal"
          spacing={{ base: 8, md: 10 }}
          ref={containerRef}>
          {(titleLineOne || titleLineTwo) && (
            <Box overflowY="hidden">
              <Heading
                as={headingType || `h2`}
                position="relative"
                fontSize={{ base: `4xl`, lg: `7xl` }}
                mb="0"
                textTransform="uppercase"
                opacity={0}
                transform={`translateY(${totalTitleHeight}px)`}
                willChange="transform, opacity"
                textAlign="center"
                ref={headerRef}>
                <Box as="span" display="block" ref={titleOneRef}>
                  {titleLineOne}
                </Box>
                <Box as="span" display="block" ref={titleTwoRef}>
                  {titleLineTwo}
                </Box>
              </Heading>
            </Box>
          )}
          {text && (
            <Text
              ref={textRef}
              textAlign="center"
              fontSize={{ base: `xl`, md: `2xl` }}
              fontWeight="600"
              m="0"
              opacity={0}
              willChange="opacity">
              {text}
            </Text>
          )}
          {ctas && ctas.length > 0 && (
            <Box ref={btnGroupRef} opacity={0} willChange="opacity">
              <ButtonGroup
                ctas={ctas}
                justify="center"
                dataLayerType="home_hero"
                size={{ base: `sm`, md: `md` }}
              />
            </Box>
          )}
        </VStack>
      </Wrapper>
    </Flex>
  );
};

export default CenteredTextBlock;
