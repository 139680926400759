import React from 'react';
import ModalVideo from '@components/molecules/ModalVideo';
import Button, { ButtonLink } from '@components/atoms/Button';
import {
  ArrowRightIcon,
  ExternalLinkIcon,
  PlayIcon
} from '@components/atoms/icons';
import dataLayerPush from '@helpers/dataLayerPush';

const getIcon = (type) => {
  let icon = null;

  switch (type) {
    case `external`:
      icon = ExternalLinkIcon;
      break;
    case `video`:
      icon = PlayIcon;
      break;
    default:
      icon = ArrowRightIcon;
      break;
  }

  return icon;
};

const ButtonCms = ({
  data: { type, title, link },
  dataLayerType,
  ...props
}) => {
  /*
   * Determine the icon based on the type eg. external, video, etc.
   */
  const Icon = getIcon(type);

  /*
   * Video modal button.
   */
  if (type === `video`) {
    return (
      <React.Fragment>
        <ModalVideo
          videoID={link}
          renderControl={(open) => (
            <Button
              {...props}
              rightIcon={<Icon />}
              onClick={() => {
                // TODO: confirm this will suffice for general video buttons.
                dataLayerPush({
                  event: `video_start`,
                  interaction: {
                    click_text: title,
                    video_url: `https://vimeo.com/${link}`,
                    video_provider: `Vimeo`
                  }
                });
                open();
              }}>
              {title}
            </Button>
          )}
        />
      </React.Fragment>
    );
  }

  /*
   * Link button.
   */
  return (
    <ButtonLink
      href={link}
      {...props}
      rightIcon={<Icon />}
      onClick={() => {
        dataLayerPush({
          event: `cta_click`,
          interaction: {
            click_text: title,
            link_url: link,
            cta_type: dataLayerType
          }
        });
      }}
      onTouchStart={() => {
        dataLayerPush({
          event: `cta_click`,
          interaction: {
            click_text: title,
            link_url: link,
            cta_type: dataLayerType
          }
        });
      }}>
      {title}
    </ButtonLink>
  );
};

export default ButtonCms;
