export const colors = {
  // Brand Rollout Colours
  colours: {
    white: `#FFF`,
    gold: `#F5C66C`,
    purple: `#4D308C`,
    lightPurple: `#7A45AA`,
    darkPurple: `#320E80`,
    darkPurpleTranslucent: `#320E80AA`,
    goldGradient: `linear-gradient(90deg, #cea558 0%, #fbe09b 40%, #cea558 100%)`
  }
};
