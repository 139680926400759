import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Hide, HStack } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SliderArrows from '@components/molecules/SliderArrows';
import SliderPagination from '@components/molecules/SliderPagination';
import ProductCard from '@components/molecules/ProductCard';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import LazyLoad from '@components/atoms/LazyLoad';

const ProductCarousel = ({ data: { products } }) => {
  const { strapiProductListing } = useStaticQuery(graphql`
    {
      strapiProductListing {
        slug
      }
    }
  `);

  return (
    <Section
      overflow="hidden"
      sx={{
        '.swiper': {
          py: 1, // to prevent overflow hidden cropping focus state on buttons.
          overflow: `visible`
        },
        '.swiper-wrapper': {
          width: `fit-content` // to ensure correct width is set on slider wrapper and dragging works.
        }
      }}>
      <LazyLoad>
        <Wrapper>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              prevEl: `.prev`,
              nextEl: `.next`
            }}
            pagination={{ el: `.pagination`, clickable: true }}
            spaceBetween={25}
            slidesPerView={1}
            breakpoints={{
              600: {
                slidesPerView: 1.75,
                spaceBetween: 30
              },
              768: {
                slidesPerView: 2.1,
                spaceBetween: 48
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 48
              },
              1200: {
                slidesPerView: 2.6,
                spaceBetween: 48
              }
            }}>
            {products &&
              products.map((product) => (
                <SwiperSlide key={product.title} className="product-hover">
                  <ProductCard
                    basePath={strapiProductListing.slug}
                    product={product}
                  />
                </SwiperSlide>
              ))}
            <HStack
              slot="container-end"
              align={{ md: `center` }}
              justifyContent={{ base: `center`, md: `flex-end` }}
              mt={{ base: 8, md: 16 }}
              spacing={{ md: 8 }}>
              <SliderPagination />
              <Hide below="md">
                <SliderArrows />
              </Hide>
            </HStack>
          </Swiper>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default ProductCarousel;
