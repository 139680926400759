import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const BrandList = ({ data: { title, brands } }) => {
  const trackSize = 3;
  const trackRef = useRef([]);
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const [totalTitleHeight, setTotalTitleHeight] = useState(0);

  const tracks = useMemo(() => {
    const arr = [];
    const brandsRow = brands.slice(0, 6);
    for (let i = 0; i < brandsRow.length; i += trackSize) {
      const track = brandsRow.slice(i, i + trackSize);
      arr.push(track);
    }
    return arr;
  }, [brands, trackSize]);

  // Get title height for title animation
  useEffect(() => {
    const titleHeight = titleRef.current?.clientHeight;
    setTotalTitleHeight(titleHeight);
  }, [titleRef]);

  // Title animation
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: containerRef.current
        }
      })
      .to(
        titleRef.current,
        {
          y: 0,
          opacity: 1
        },
        0.5
      );
  }, []);

  // Scrub animation
  useEffect(() => {
    let scrub = 0.5;
    const animations = tracks.map((_track, index) => {
      const isEvenIndex = index % 2 === 0;
      const startX = isEvenIndex ? `4%` : `-3%`;
      const endX = isEvenIndex ? `-5%` : `3%`;
      scrub += 1;
      return gsap.fromTo(
        trackRef.current[index],
        { x: startX },
        {
          x: endX,
          ease: `none`,
          scrollTrigger: {
            trigger: trackRef.current[index],
            scrub,
            start: `top 100%`,
            end: `bottom 0%`
          }
        }
      );
    });

    return () => animations.forEach((animation) => animation.kill());
  }, [tracks]);

  return (
    <Box ref={containerRef} my="8" w="100%" overflowX="hidden">
      <Box overflowY="hidden">
        <Heading
          as="h2"
          ref={titleRef}
          textTransform="uppercase"
          textAlign="center"
          fontSize={{ base: `4xl`, lg: `7xl` }}
          width="100%"
          px="1.5rem"
          opacity={0}
          transform={`translateY(${totalTitleHeight}px)`}
          willChange="opacity, transform">
          {title}
        </Heading>
      </Box>
      {tracks.map((track, index) => {
        return (
          <Flex
            ref={(element) => {
              trackRef.current[index] = element;
            }}
            key={`tracks-group${index + 1}`}
            justifyContent="space-evenly"
            mb={
              index !== tracks.length - 1
                ? {
                    base: `5`,
                    md: `10`
                  }
                : null
            }>
            {track.length &&
              track.map(({ name, image }, i) => (
                <Flex
                  key={`${`${name}-${i}`}`}
                  align="center"
                  mx="1"
                  flex="0 0 auto"
                  width={{ base: `33%`, lg: `15%` }}
                  height="auto"
                  whiteSpace="nowrap">
                  {image && <Image image={image} alt={name} />}
                </Flex>
              ))}
          </Flex>
        );
      })}
    </Box>
  );
};

export default BrandList;
