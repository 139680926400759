import React, { useMemo } from 'react';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import {
  Flex,
  Box,
  Heading,
  AspectRatio,
  HStack,
  Hide
} from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Image from '@components/atoms/Image';
import { ButtonLink } from '@components/atoms/Button';
import SliderArrows from '@components/molecules/SliderArrows';
import SliderPagination from '@components/molecules/SliderPagination';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import dataLayerPush from '@helpers/dataLayerPush';
import LazyLoad from '@components/atoms/LazyLoad';

const NewsCarousel = ({ data: { cta, entries, tags, carouselCtaText } }) => {
  /**
   * Get list of latest entries in case we need them.
   */
  const {
    allStrapiArticle: { nodes: allArticles },
    strapiArticleListing
  } = useStaticQuery(graphql`
    {
      strapiArticleListing {
        slug
      }
      allStrapiArticle(sort: { publishDate: DESC }) {
        nodes {
          publishDate
          title
          slug
          tags {
            tagName
            tagId
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);
  /**
   * Determine whether to use the latest entries or custom list.
   */
  const entriesList = useMemo(() => {
    // Filter down the latest entries by tag if one has been selected.
    if (tags && tags.length > 0) {
      return allArticles.filter((entry) => {
        const filteredEntries = entry.tags.filter(
          ({ tagId }) => tags.some((selectedTag) => tagId === selectedTag.tagId)
          // eslint-disable-next-line function-paren-newline
        );

        return filteredEntries.length > 0;
      });
    }

    // Use the latest entries when no custom list is entered
    if (entries.length === 0) {
      return allArticles.slice(0, 6);
    }

    return entries;
  }, [entries, tags, allArticles]);
  return (
    <Section
      bg="colours.purple"
      py={{ base: 12, md: 24 }}
      overflow="hidden"
      sx={{
        '.swiper': {
          py: 1, // to prevent overflow hidden cropping focus state on buttons.
          overflow: `visible`
        },
        '.swiper-wrapper': {
          width: `fit-content` // to ensure correct width is set on slider wrapper and dragging works.
        },
        '.swiper-slide': {
          height: `auto`
        }
      }}>
      <LazyLoad>
        <Wrapper>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              prevEl: `.prev`,
              nextEl: `.next`
            }}
            pagination={{ el: `.pagination`, clickable: true }}
            spaceBetween={25}
            slidesPerView={1.15}
            breakpoints={{
              600: {
                slidesPerView: 1.5,
                spaceBetween: 24
              },
              768: {
                slidesPerView: 1.75,
                spaceBetween: 24
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 48
              }
            }}>
            {entriesList &&
              entriesList.map((entry) => (
                <SwiperSlide key={entry.title}>
                  <Box className="button-hover">
                    <Box
                      as={GatsbyLink}
                      to={`${strapiArticleListing.slug}/${entry.slug}`}>
                      <AspectRatio
                        ratio={4 / 3}
                        overflow="hidden"
                        display="block">
                        <Image image={entry.thumbnail} />
                      </AspectRatio>
                      <Heading
                        as="h2"
                        fontSize={{ base: `lg`, md: `3xl` }}
                        color="white"
                        pt={{ base: 8, md: 10 }}
                        mb={{ base: 6, md: 8 }}
                        textTransform="uppercase">
                        {entry.title}
                      </Heading>
                    </Box>
                    <ButtonLink
                      href={`${strapiArticleListing.slug}/${entry.slug}`}
                      variant="tertiary"
                      size="ellipsed"
                      icon
                      onClick={() => {
                        dataLayerPush({
                          event: `cta_click`,
                          interaction: {
                            click_text: entry.title,
                            link_url: `${strapiArticleListing.slug}/${entry.slug}`,
                            cta_type: `news_card`
                          }
                        });
                      }}>
                      {carouselCtaText}
                    </ButtonLink>
                  </Box>
                </SwiperSlide>
              ))}
            <Flex
              slot="container-end"
              align="center"
              justify={{ md: `space-between` }}
              direction={{ base: `column`, md: `row-reverse` }}
              mt={{ base: 8, md: 16 }}>
              <HStack align={{ md: `center` }} spacing={{ md: 8 }}>
                <SliderPagination />
                <Hide below="md">
                  <SliderArrows />
                </Hide>
              </HStack>
              {cta && (
                <Hide below="md">
                  <ButtonLink
                    variant="primary"
                    href={cta.link}
                    size="md"
                    mt={{ base: 8, md: 0 }}
                    onClick={() => {
                      dataLayerPush({
                        event: `cta_click`,
                        interaction: {
                          click_text: cta.title,
                          link_url: cta.link,
                          cta_type: `news_carousel`
                        }
                      });
                    }}>
                    <Box position="relative" overflow="hidden">
                      <Box
                        className="button__inner"
                        as="span"
                        data-text={cta.title}>
                        {cta.title}
                      </Box>
                    </Box>
                  </ButtonLink>
                </Hide>
              )}
            </Flex>
          </Swiper>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default NewsCarousel;
