import React, { lazy } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SuspenseLoader from '@components/atoms/SuspenseLoader';
import SuspenseHelper from '@components/atoms/SuspenseHelper';

/* Lazy-load share page block to avoid SSR issues */
const SharePageBlock = lazy(
  () => import(`@components/atoms/SharePageBlock`)
  // eslint-disable-next-line function-paren-newline
);

const Share = ({ metaTitle, metaDescription, link }) => {
  const {
    strapiSocialSharing: { buttonText }
  } = useStaticQuery(graphql`
    {
      strapiSocialSharing {
        buttonText
        shareTo
      }
    }
  `);
  return (
    <SuspenseHelper fallback={<SuspenseLoader />}>
      <SharePageBlock
        buttonText={buttonText}
        link={link}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        noReferer
      />
    </SuspenseHelper>
  );
};

export default Share;
