import React from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { Box, Flex, Text, Image, keyframes } from '@chakra-ui/react';
import FooterNav from '@components/molecules/FooterNav';
import Wrapper from '@components/atoms/Wrapper';
import logo from '@assets/images/cadbury-logo.png';
import LazyLoad from '@components/atoms/LazyLoad';

// Keyframe Animations.
const logoScale = keyframes`
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
`;

const Footer = ({ footerMenu }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle }
    },
    strapiFooter
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      strapiFooter {
        copyright
      }
    }
  `);

  if (!strapiFooter) {
    return null;
  }

  const { copyright } = strapiFooter;

  return (
    <Box as="footer" bg="colours.purple" color="white">
      <LazyLoad>
        <Wrapper maxWidth="none" px="0">
          <Flex
            direction={{
              base: `column`,
              xl: `row-reverse`
            }}
            alignItems={{ lg: `flex-start` }}
            justifyContent={{ md: `space-between` }}
            py="2.375rem"
            px={{ lg: `3.75rem` }}
            borderTop="1px"
            borderColor="colours.gold">
            <Flex
              width={{ lg: `100%`, xl: `auto` }}
              direction={{ base: `column`, lg: `row` }}
              justifyContent={{ lg: `space-between` }}
              gap={{ lg: `6.6rem` }}>
              <FooterNav footerMenu={footerMenu} />
            </Flex>
            <Box mt={{ base: 10, xl: 0 }} mr={{ md: 10 }}>
              <Flex
                as={GatsbyLink}
                to="/"
                width="8.375rem"
                mx={{ base: `auto`, lg: 0 }}
                mb={{ base: 6, md: 2 }}
                sx={{
                  ':hover': {
                    animation: `${logoScale} .7s ease-out`
                  }
                }}>
                <Image src={logo} alt={siteTitle} />
              </Flex>
              {copyright && (
                <Text
                  fontSize="sm"
                  mb="0"
                  px={{ base: `2`, sm: 0 }}
                  textAlign={{ base: `center`, lg: `left` }}>
                  {copyright}
                </Text>
              )}
            </Box>
          </Flex>
        </Wrapper>
      </LazyLoad>
    </Box>
  );
};

export default Footer;
