import React, { useEffect, useRef, useState } from 'react';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import { Box, Heading, Text, Flex, Grid, GridItem } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
}

const ProductHero = ({
  title,
  subtitle,
  description,
  image,
  productVariants
}) => {
  const { strapiProductListing } = useStaticQuery(graphql`
    {
      strapiProductListing {
        slug
      }
    }
  `);
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const imageRef = useRef(null);
  const [totalTitleHeight, setTotalTitleHeight] = useState(0);

  useEffect(() => {
    const titleHeight = titleRef.current?.clientHeight;
    setTotalTitleHeight(titleHeight);
  }, [titleRef]);

  useGSAP(() => {
    // Start Gsap timeline.
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current
      }
    });
    if (titleRef.current != null) {
      tl.to(
        titleRef.current,
        {
          y: 0,
          opacity: 1
        },
        0.5
      );
    }
    if (contentRef.current != null) {
      tl.to(contentRef.current, { opacity: 1, duration: 1 }, 1);
    }
    if (imageRef.current != null) {
      tl.to(imageRef.current, { opacity: 1, duration: 1 }, 1.5);
    }
  }, [containerRef, titleRef, contentRef, imageRef]);

  return (
    <Box
      as="section"
      position="relative"
      bg="colours.purple"
      ref={containerRef}
      _after={{
        content: `""`,
        position: `absolute`,
        width: `60%`,
        height: `70%`,
        left: `50%`,
        top: `50%`,
        transform: `translate(-50%, -50%)`,
        background: `colours.purple`,
        filter: `blur(106px)`,
        zIndex: `0`
      }}>
      <Wrapper>
        <Grid
          className="grid"
          position="relative"
          templateColumns={{ md: `repeat(12, 1fr)` }}
          columnGap={5}
          alignItems="center"
          minHeight={{ lg: `44rem` }}
          maxHeight={{ lg: `56.25rem` }}
          pt={{ base: `6.625rem`, md: headerHeight }}
          pb={{ base: 10, md: 24 }}
          zIndex={1}>
          <GridItem
            ref={imageRef}
            order={{ md: 2 }}
            colStart={{ xl: 7 }}
            colSpan={{ md: 6 }}
            mb={{ base: 6, md: 0 }}
            opacity={0}
            willChange="opacity"
            sx={{
              '> .gatsby-image-wrapper': {
                flexGrow: 1
              }
            }}>
            {image && <Image image={image} loading="eager" />}
          </GridItem>
          <GridItem
            position="relative"
            order={{ md: 1 }}
            colStart={{ xl: 2 }}
            colSpan={{ md: 6, xl: 4 }}>
            <Box>
              {title && (
                <Box overflowY="hidden">
                  <Heading
                    as="h1"
                    ref={titleRef}
                    size="4xl"
                    lineHeight="1.1"
                    mb="8"
                    textTransform="uppercase"
                    opacity={0}
                    transform={`translateY(${totalTitleHeight}px)`}
                    willChange="transform, opacity">
                    {title}
                  </Heading>
                </Box>
              )}
              <Box ref={contentRef} opacity={0} willChange="opacity">
                {subtitle && (
                  <Heading
                    color="colours.white"
                    fontFamily="heading"
                    textTransform="uppercase"
                    fontSize="2xl"
                    mb="4">
                    {subtitle}
                  </Heading>
                )}
                {productVariants?.length > 0 && (
                  <Flex
                    bgColor="colours.darkPurple"
                    height="3.4375rem"
                    borderRadius="3.5rem"
                    maxWidth="19.125rem"
                    mb={4}>
                    {productVariants.map(
                      (variant) =>
                        variant.product !== null && (
                          <Flex
                            key={variant.id}
                            as={GatsbyLink}
                            to={`${strapiProductListing.slug}/${variant.product.slug}`}
                            fontFamily="heading"
                            fontSize="md"
                            alignItems="center"
                            justifyContent="center"
                            flex="1"
                            height="100%"
                            textAlign="center"
                            textTransform="uppercase"
                            bg={
                              variant.currentVariant
                                ? `linear-gradient(90deg, #cea558 0%, #fbe09b 40%, #cea558 100%)`
                                : `transparent`
                            }
                            color={
                              variant.currentVariant
                                ? `colours.darkPurple`
                                : `colours.white`
                            }
                            borderRightRadius="3.5rem"
                            borderRadius={
                              variant.currentVariant ? `3.5rem` : null
                            }>
                            {variant.productVarientSize}
                          </Flex>
                        )
                    )}
                  </Flex>
                )}
                {description && (
                  <Text fontSize="xl" mb="10" fontWeight="bold">
                    {description}
                  </Text>
                )}
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </Wrapper>
    </Box>
  );
};

export default ProductHero;
