import React from 'react';
import { Global } from '@emotion/react';
import Effra from '@assets/fonts/Effra-He.ttf';
import Myriad from '@assets/fonts/MyriadPro-Regular.woff2';
import MyriadSemibold from '@assets/fonts/MyriadPro-Semibold.woff2';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Effra';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${Effra}) format('truetype');
      }
      @font-face {
        font-family: 'Myriad';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url(${Myriad}) format('woff2');
      }
      @font-face {
        font-family: 'Myriad';
        font-weight: 600;
        font-style: normal;
        font-display: swap;
        src: url(${MyriadSemibold}) format('woff2');
      }
    `}
  />
);

export default Fonts;
