import React, { useEffect, useRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Flex, Box, Heading, AspectRatio } from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import Glow from '@assets/images/ellipse-glow.png';
import Image from '../atoms/Image';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, CustomEase);
}

const ProductListingRow = ({
  title,
  slug,
  thumbnail,
  isEven,
  parentSlug,
  ...props
}) => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: imageRef.current,
        start: `top bottom`
      }
    });
    tl.to(imageRef.current, {
      opacity: 1,
      duration: 0.42,
      immediateRender: false
    });
    tl.to(imageRef.current, {
      scale: 1,
      y: 0,
      duration: 1,
      immediateRender: false,
      ease: CustomEase.create(`customBounce`, `0.53, 2, 0.63, 0.53`)
    });
  }, []);
  return (
    <Flex
      as={GatsbyLink}
      to={`${parentSlug}/${slug}`}
      className="product-hover"
      {...props}
      alignItems="center"
      flexDirection={{
        base: `column`,
        md: isEven ? `row-reverse` : `row`
      }}
      mb={{ base: 14, md: `7.5rem` }}
      ref={containerRef}>
      <Box
        w={{ base: `100%`, md: `50%` }}
        textAlign="center"
        position="relative"
        transition="transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)"
        sx={{
          '.product-hover:hover &': {
            transform: { md: `auto` },
            scale: { md: `1.1` }
          }
        }}>
        <AspectRatio ratio={3 / 2}>
          <Image image={thumbnail} width="100%" />
        </AspectRatio>
        <Heading
          as="h2"
          fontSize={{ base: `3xl`, md: `5xl` }}
          textAlign="center"
          textTransform="uppercase"
          mt={6}
          mb={0}
          sx={{
            '.product-hover:hover &': {
              textShadow: { md: `1rem 1rem 0.625rem #320E80CC` }
            }
          }}>
          {title}
        </Heading>
        <Box
          position="absolute"
          top="1rem"
          left="50%"
          width="100%"
          height="100%"
          transform="translateX(-50%)"
          backgroundImage={Glow}
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          zIndex="-1"
          opacity="0"
          transition="opacity .3s ease-in-out"
          sx={{
            '.product-hover:hover &': {
              opacity: { md: 1 }
            }
          }}
        />
      </Box>
    </Flex>
  );
};

export default ProductListingRow;
