import React from 'react';
import { Box, Grid, GridItem, AspectRatio, Text, Link } from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import buttonBgGradient from '@assets/images/button-bg-gradient.svg';
import LazyLoad from '@components/atoms/LazyLoad';

const WhereToBuy = ({ data: { retailers } }) => {
  return (
    <Section>
      <LazyLoad>
        <Wrapper position="relative" zIndex="1">
          <Grid
            templateColumns="repeat(12, 1fr)"
            columnGap={{ base: 5, sm: 8, md: 10 }}
            rowGap={8}>
            {typeof retailers !== `undefined` &&
              retailers.map((retailer) => {
                const wrappedLink = retailer.link?.startsWith(`https://`)
                  ? retailer.link
                  : `https://${retailer.link}`;

                return (
                  <GridItem
                    key={retailer.id}
                    colSpan={{ base: 6, lg: 3 }}
                    className="product-hover">
                    <Link href={wrappedLink} isExternal>
                      {retailer.image && (
                        <AspectRatio
                          ratio={1 / 1}
                          overflow="hidden"
                          transition="transform .5s ease-out"
                          sx={{
                            '.product-hover:hover &': {
                              transform: { md: `auto` },
                              scale: { md: `1.1` }
                            }
                          }}>
                          <Image image={retailer.image} />
                        </AspectRatio>
                      )}
                      <Box
                        backgroundClip="text"
                        backgroundImage={`/**/url("${buttonBgGradient}"), linear-gradient(90deg, white 0%, white 100%)`}
                        backgroundSize="cover"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="0 4rem, 0 0"
                        position="relative"
                        w="fit-content"
                        h="fit-content"
                        mx="auto"
                        sx={{
                          '.product-hover:hover &': {
                            backgroundPosition: `0 -4rem, 0 0`,
                            transition: `background-position .7s ease-in-out`
                          }
                        }}
                        transition="background-position 0.5s ease-out">
                        <Text
                          fontFamily="heading"
                          fontSize={{ base: `lg`, md: `3xl` }}
                          pt={{ base: 2, md: 4 }}
                          letterSpacing="0.15rem"
                          textAlign="center"
                          textTransform="uppercase"
                          color="transparent"
                          backgroundClip="text"
                          backgroundImage="linear-gradient(90deg, #cea558 0%, #fbe09b 40%, #cea558 100%)"
                          backgroundPosition="0 4rem"
                          backgroundSize="cover"
                          backgroundRepeat="no-repeat"
                          transition="background-position .5s 0s ease-in-out"
                          sx={{
                            '.product-hover:hover &': {
                              backgroundPosition: `0 0`,
                              transition: `background-position .7s .1s ease-out`
                            }
                          }}
                          mb={0}>
                          {retailer.title}
                        </Text>
                      </Box>
                    </Link>
                  </GridItem>
                );
              })}
          </Grid>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default WhereToBuy;
