import React, { useState, useEffect, useCallback, useRef } from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import dataLayerPush from '@helpers/dataLayerPush';

import {
  Box,
  Flex,
  Link,
  useDisclosure,
  Image,
  HStack,
  keyframes
} from '@chakra-ui/react';
import Burger from '@components/atoms/Burger';
import logo from '@assets/images/cadbury-logo.png';

export const height = { base: `66px`, md: `147px` };

// Keyframe Animations.
const logoScale = keyframes`
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
`;

const Header = ({ headerMenu }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle }
    }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { isOpen, onToggle } = useDisclosure();
  const [showNav, setShowNav] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const [currentNavItemIndex, setCurrentNavItemIndex] = useState(-1);

  const navItemsRef = useRef([]);

  const handleClick = useCallback((eventName, url, title, navLevel) => {
    dataLayerPush({
      event: eventName,
      interaction: {
        click_text: title,
        link_url: url,
        nav_level: navLevel || 1
      }
    });
  }, []);
  useEffect(() => {
    /**
     * onScroll method for the header
     * @param {WheelEvent} event onScroll event
     */
    const onScroll = (event) => {
      if (event.deltaY > 0) setShowNav(false);
      else if (event.deltaY < 0) setShowNav(true);
    };

    window.addEventListener(`wheel`, onScroll);

    return () => window.removeEventListener(`wheel`, onScroll);
  }, []);

  useEffect(() => {
    /**
     * atTop method for the header
     */
    const atTop = () => {
      const scroll = window.scrollY;

      if (scroll <= 0) setIsAtTop(true);
      else if (scroll > 0) setIsAtTop(false);
    };

    window.addEventListener(`scroll`, atTop);

    return () => window.removeEventListener(`scroll`, atTop);
  }, []);

  useEffect(() => {
    /**
     * onKeyDown method for the header
     * @param {KeyboardEvent} event onKeyDown event
     */
    const onKeyDown = ({ key }) => {
      const menuItems = headerMenu.nodes[0]?.attributes.items.data.map(
        (item, index) => index
      );

      if (typeof key === `undefined`) {
        return;
      }

      if (key.toLowerCase() === `arrowleft`) {
        const previousIndex = currentNavItemIndex - 1;
        const previousItem =
          previousIndex < 0 ? menuItems.length - 1 : previousIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[previousItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(previousItem);
      }

      if (key.toLowerCase() === `arrowright`) {
        const nextIndex = currentNavItemIndex + 1;
        const nextItem = nextIndex > menuItems.length - 1 ? 0 : nextIndex;

        if (navItemsRef.current && navItemsRef.current.length > 0) {
          const currentElement = navItemsRef.current[nextItem];
          currentElement.focus();
        }

        setCurrentNavItemIndex(nextItem);
      }
    };

    window.addEventListener(`keydown`, onKeyDown);

    return () => window.removeEventListener(`keydown`, onKeyDown);
  }, [
    currentNavItemIndex,
    headerMenu.nodes,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    headerMenu.nodes[0]?.attributes.items.data
  ]);

  return headerMenu?.nodes[0]?.attributes === undefined ||
    headerMenu.nodes[0]?.attributes.items?.data?.length <= 0 ? (
    // eslint-disable-next-line react/jsx-indent
    <Box color="red">Menu render error</Box>
  ) : (
    <Box position="relative" zIndex="100">
      <Flex w="100%" justifyContent="center">
        <Flex
          w="100vw"
          h={{ base: height.base, md: height.md }}
          alignItems="center"
          justifyContent="space-between"
          px={{ base: 6, lg: 14 }}
          position="fixed"
          bg={isAtTop ? `transparent` : `colours.darkPurple`}
          top={showNav || isOpen ? `0px` : `-${height.md}`}
          zIndex="2"
          transition="top 0.5s ease-in-out, background-color .3s .3s ease-in-out">
          <Flex
            to="/"
            className="logo-hover"
            as={GatsbyLink}
            position="relative"
            width={{ base: `5.676rem`, md: `8.375rem` }}
            mr={{ base: `0`, xl: `4` }}
            zIndex="2"
            onClick={
              () => handleClick(`nav_logo_click`, `/`, `logo`, `logo`)
              // eslint-disable-next-line react/jsx-curly-newline
            }>
            <Box
              display={{ base: `none`, md: `block` }}
              position="absolute"
              top="-3.125rem"
              left={0}
              width="7.675rem"
              height="8.375rem"
              backgroundRepeat="no-repeat"
              transition="background-position ease-in-out .2s"
              opacity={0}
            />
            <Image
              src={logo}
              alt={siteTitle}
              transform="scale(1)"
              transition="transform 0.3s ease-out"
              sx={{
                '.logo-hover:hover &': {
                  animation: `${logoScale} .7s ease-out`
                }
              }}
            />
          </Flex>
          <Flex
            as="nav"
            role="navigation"
            position={{ base: `fixed`, md: `static` }}
            top={{ base: `0`, md: `auto` }}
            right={{ base: `0`, md: `auto` }}
            left={{ base: `0`, md: `auto` }}
            bottom={{ base: `0`, md: `auto` }}
            display="flex"
            flexDirection={{ base: `column`, md: `row` }}
            alignItems="center"
            backgroundColor={{ base: `colours.darkPurple`, md: `transparent` }}
            py={{ base: `4.125rem`, md: 0 }}
            ml="auto"
            transform="auto"
            translateY={isOpen ? `0` : { base: `-100%`, md: `0` }}
            transition=".3s ease-in-out">
            <HStack
              as="ul"
              px={{ base: 6, md: 0 }}
              flexDirection={{ base: `column`, md: `row` }}
              listStyleType="none"
              spacing={{ base: 6, lg: 14 }}
              pt={{ base: 10, md: 0 }}
              mr={{ md: 4, lg: 0 }}>
              {headerMenu.nodes[0].attributes.items.data.map(
                ({ attributes: primaryItem }) => (
                  <Box as="li" key={primaryItem.title}>
                    <Link
                      href={`${primaryItem.url}`}
                      display="block"
                      fontFamily="heading"
                      fontWeight="bold"
                      fontSize={{ base: `3xl`, md: `lg` }}
                      lineHeight="1.2"
                      textTransform="uppercase"
                      textDecoration="none"
                      transition=".3s ease-in-out"
                      letterSpacing="0.1237rem"
                      _hover={{
                        transform: `auto`,
                        scale: `1.1`,
                        textShadow: `0.2rem 0.2rem 0.5rem #320E80CC`
                      }}>
                      {primaryItem.title}
                    </Link>
                  </Box>
                )
              )}
            </HStack>
          </Flex>
          <Flex
            display={{ base: `block`, md: `none` }}
            transform="auto"
            rotate={isOpen ? `90deg` : 0}
            transition={isOpen ? `transform .2s  ease-in-out` : null}>
            <Burger
              isOpen={isOpen}
              onClick={onToggle}
              color="colours.white"
              zIndex="2"
              cursor="pointer"
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
