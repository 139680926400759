import React from 'react';
import { HStack } from '@chakra-ui/react';

const SliderPagination = () => (
  <HStack
    className="pagination"
    spacing="3"
    justifyContent="center"
    sx={{
      '.swiper-pagination-bullet': {
        display: `block`,
        width: 3,
        height: 1,
        minWidth: `1.5rem`,
        minHeight: `0.5rem`,
        background: `colours.lightPurple`,
        borderRadius: `0.5rem`,
        cursor: `pointer`,
        transition: `.3s`
      },
      '.swiper-pagination-bullet:hover': {
        background: `colours.white`
      },
      '.swiper-pagination-bullet-active': {
        background: `colours.white`
      }
    }}
  />
);

export default SliderPagination;
