import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import { ButtonLink } from '@components/atoms/Button';
import dataLayerPush from '@helpers/dataLayerPush';

const ImageBlock = ({ data: { image, cta } }) => {
  return (
    <Flex
      px={{ base: `6`, md: `36` }}
      direction="column"
      alignItems="center"
      w="100%"
      gap="6"
      my="6">
      <Box maxW="1150px">
        <Image image={image} />
      </Box>
      {cta && (
        <ButtonLink
          variant="primary"
          href={cta.link}
          size="md"
          mt={{ base: 8, md: 0 }}
          onClick={() => {
            dataLayerPush({
              event: `cta_click`,
              interaction: {
                click_text: cta.title,
                link_url: cta.link,
                cta_type: `image_block`
              }
            });
          }}>
          <Box position="relative" overflow="hidden">
            <Box className="button__inner" as="span" data-text={cta.title}>
              {cta.title}
            </Box>
          </Box>
        </ButtonLink>
      )}
    </Flex>
  );
};

export default ImageBlock;
