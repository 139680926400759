import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@components/atoms/icons';

const hoverStyles = {
  bg: null
};

const iconHoverStyles = {
  '.chakra-button:hover &': {
    color: `colours.gold`
  },
  // Prevent disabled icon from changing colour on hover state.
  '.chakra-button.swiper-button-disabled:hover &': {
    color: `colours.white`
  }
};

const SliderArrows = () => {
  return (
    <ButtonGroup
      border="1px solid"
      borderColor="colours.gold"
      borderRadius="3rem"
      bgColor="colours.purple"
      h="14"
      mt={{ base: `auto`, md: `0` }}
      sx={{
        '> .swiper-button-disabled[disabled]': {
          opacity: 1
        },
        '> .swiper-button-disabled[disabled] > svg': {
          opacity: 0.5
        }
      }}
      isAttached>
      <Button
        className="prev"
        color="colours.white"
        h="14"
        p="4"
        py="0"
        pr="2.5"
        _hover={hoverStyles}>
        <ArrowLeftIcon color="colours.white" w="6" h="6" sx={iconHoverStyles} />
      </Button>
      <Button
        className="next"
        color="colours.white"
        h="14"
        p="4"
        py="0"
        pl="2.5"
        _hover={hoverStyles}>
        <ArrowRightIcon
          color="colours.white"
          w="6"
          h="6"
          sx={iconHoverStyles}
        />
      </Button>
    </ButtonGroup>
  );
};

export default SliderArrows;
